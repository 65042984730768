import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

var exports = {};
var path = _path;
var fs = _fs;

var _0777 = parseInt("0777", 8);

exports = mkdirP.mkdirp = mkdirP.mkdirP = mkdirP;

function mkdirP(p, opts, f, made) {
  if (typeof opts === "function") {
    f = opts;
    opts = {};
  } else if (!opts || typeof opts !== "object") {
    opts = {
      mode: opts
    };
  }

  var mode = opts.mode;
  var xfs = opts.fs || fs;

  if (mode === undefined) {
    mode = _0777;
  }

  if (!made) made = null;

  var cb = f ||
  /* istanbul ignore next */
  function () {};

  p = path.resolve(p);
  xfs.mkdir(p, mode, function (er) {
    if (!er) {
      made = made || p;
      return cb(null, made);
    }

    switch (er.code) {
      case "ENOENT":
        /* istanbul ignore if */
        if (path.dirname(p) === p) return cb(er);
        mkdirP(path.dirname(p), opts, function (er, made) {
          /* istanbul ignore if */
          if (er) cb(er, made);else mkdirP(p, opts, cb, made);
        });
        break;
      // In the case of any other error, just see if there's a dir
      // there already.  If so, then hooray!  If not, then something
      // is borked.

      default:
        xfs.stat(p, function (er2, stat) {
          // if the stat fails, then that's super weird.
          // let the original error be the failure reason.
          if (er2 || !stat.isDirectory()) cb(er, made);else cb(null, made);
        });
        break;
    }
  });
}

mkdirP.sync = function sync(p, opts, made) {
  if (!opts || typeof opts !== "object") {
    opts = {
      mode: opts
    };
  }

  var mode = opts.mode;
  var xfs = opts.fs || fs;

  if (mode === undefined) {
    mode = _0777;
  }

  if (!made) made = null;
  p = path.resolve(p);

  try {
    xfs.mkdirSync(p, mode);
    made = made || p;
  } catch (err0) {
    switch (err0.code) {
      case "ENOENT":
        made = sync(path.dirname(p), opts, made);
        sync(p, opts, made);
        break;
      // In the case of any other error, just see if there's a dir
      // there already.  If so, then hooray!  If not, then something
      // is borked.

      default:
        var stat;

        try {
          stat = xfs.statSync(p);
        } catch (err1)
        /* istanbul ignore next */
        {
          throw err0;
        }
        /* istanbul ignore if */


        if (!stat.isDirectory()) throw err0;
        break;
    }
  }

  return made;
};

export default exports;